import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

const ThundercastNews = () => {
  const thunderCastQuery = useStaticQuery(graphql`
    query {
      allContentfulLindseyThunderCastEpisodes(
        sort: { fields: [episodeNumber], order: DESC }
      ) {
        nodes {
          title
          episodeNumber
          episodeEmbed
        }
      }
    }
  `);

  const thundercastEpisodes = thunderCastQuery.allContentfulLindseyThunderCastEpisodes.nodes.map(
    (episode) => ({
      title: episode.title,
      episodeNumber: episode.episodeNumber,
      thundercastImage: episode.thundercastImage,
      episodeEmbed: episode.episodeEmbed,
    }),
  );

  return (
    <div className="thundercast_episodes_wrapper">
      <iframe
        width="100%"
        height="350"
        src="https://embeds.audioboom.com/publishing/playlist/v4?boo_content_type=channel&data_for_content_type=5028510"
        style={{
          backgroundColor: 'transparent',
          display: 'block',
          padding: '0',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          maxWidth: '50rem',
        }}
        frameBorder="0"
        allowtransparency="allowtransparency"
        scrolling="no"
        title="Audioboom player"
        allow="autoplay"
      ></iframe>
      {/* {thundercastEpisodes.map((episode) => {
        return (
          <iframe
            width="100%"
            height="275"
            src={episode.episodeEmbed}
            style={{
              backgroundColor: 'transparent',
              display: 'block',
              padding: '0',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              maxWidth: '25rem',
            }}
            frameBorder="0"
            allowtransparency="allowtransparency"
            scrolling="no"
            title="Audioboom player"
            allow="autoplay"
            key={episode.title}
          ></iframe>
        );
      })} */}
    </div>
  );
};

export default ThundercastNews;
