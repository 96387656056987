import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

const NetballNews = () => {
  const netballQuery = useStaticQuery(graphql`
    query {
      allContentfulBlogpost(
        limit: 6
        sort: { fields: [date], order: DESC }
        filter: { tags: { regex: "/(N|n)(etball)/" } }
      ) {
        nodes {
          id
          title
          body {
            body
          }
          slug
          tags
          imageMultiple {
            title
            description
            fixed(width: 275, height: 200) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  `);

  const netballNews = netballQuery.allContentfulBlogpost.nodes.map((post) => ({
    id: post.id,
    title: post.title,
    body: post.body.body,
    slug: post.slug,
    tags: post.tags,
    imageMultiple: post.imageMultiple,
  }));

  return (
    <div className="netball_news_wrapper">
      {netballNews.map((post) => {
        return (
          <article className="postPreview" key={post.slug}>
            <Link
              to={`/${post.slug}`}
              className="linkToImage"
              title={`/${post.slug}`}
              aria-label={`/${post.slug}`}
            >
              <Image
                className="postPreview__Image"
                alt={post.imageMultiple[0].fixed.description}
                fixed={post.imageMultiple[0].fixed}
                key={post.id}
              />
            </Link>
            <h2 className="postPreviewTitle">
              <Link
                to={`/${post.slug}`}
                style={{
                  textDecoration: 'none',
                  fontSize: '2rem',
                  color: ' $color-3',
                }}
              >
                {post.title}
              </Link>
            </h2>
          </article>
        );
      })}
    </div>
  );
};

export default NetballNews;
