/* eslint-disable react/display-name */
import React from 'react';
import NetballNews from '../components/netballNews';
import ThundercastNews from '../components/thundercastNews';
import { Link } from 'gatsby';

export default () => {
  return (
    <div className="netball__wrapper">
      <h1 className="netball__header"> International Netball Commentator </h1>
      <div className="netball__video">
        <div className="video">
          <iframe
            width="560"
            height="349"
            style={{ borderRadius: '1rem' }}
            src="https://www.youtube.com/embed/VMnb7NScwo4"
            title="Lindsey Chapman netball commentary"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </div>
      <div className="netball__body">
        Lindsey is an international Netball presenter, commentator and reporter.
        She spearheaded the BBC&apos;s coverage of the Netball World Cup 2019,
        live from Liverpool, including commentating the Grand Final for the BBC
        World Service and BBC Radio 5 live.{' '}
        <span style={{ fontWeight: 'bold' }}>
          (Click the picture to listen now).
        </span>
        <br />
        <br />
        Lindsey is lead commentator for <strong>Sky Sports Netball</strong>{' '}
        covering the Vitality Netball Superleague, Britain&apos;s elite level
        competition. She is also the proud host of{' '}
        <strong>Courtside - the Official England Netball Podcast. </strong>
        <a href="https://audioboom.com/channels/5075010" target="blank">
          Listen here.
        </a>{' '}
        <br />
        <br />
        Netball is a leading female team sport in the UK. Having played since
        the age of 6, she continues to captain her local side and has been an
        England Roses fan since Tracey Neville signed her game programme in the
        90s!
        <br />
        <br />
        Lindsey has anchored coverage of the{' '}
        <strong>
          Netball World Cup, Netball Nations Cup, International Quad Series,
          Vitality Netball Superleague and the Ugandan Tri-Series.
        </strong>{' '}
        During the 2020 Covid-19 Pandemic, she presented the Netball Rise Again
        Festival - the first live netball to be played on British soil in over
        10 months.
        <br />
        <br />
        For great insight into the world of netball, join Lindsey on the{' '}
        <strong>Thundercast</strong> podcast from Manchester Thunder, the
        leading Netball Superleague franchise in the UK.{' '}
        <span style={{ fontWeight: 'bold' }}>Scroll down to listen</span> to big
        name guests, expert insight and match analysis.
        <br />
      </div>
      <div className="netball_news">Netball News</div>
      <div className="netball_newsTiles">
        <NetballNews />
      </div>
      <div className="netball_newsMore">
        <div className="netball_linkToNews">
          <Link to="/news">More News</Link>
        </div>
      </div>
      <div className="netball_thundercast">Thundercast - Podcast Series</div>
      <div className="netball_thundercastTiles">
        <ThundercastNews />
      </div>
      <div className="netball_thundercastMore">
        <a href="https://audioboom.com/channels/5028510" target="blank">
          Thundercast on audioboom
        </a>
      </div>

      <div className="netball__left"></div>
      <div className="netball__right"></div>
    </div>
  );
};
